/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef } from 'react';

import { css, jsx, keyframes } from '@compiled/react';

import WarningIcon from '@atlaskit/icon/utility/warning';
import { Box, Pressable, type PressableProps, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { SparkleIcon } from './SparkleIcon';

const buttonStyles = xcss({
	borderWidth: 'border.width',
	font: token('font.body'),
	borderStyle: 'solid',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius.100',
	paddingInline: 'space.150',
	paddingBlock: 'space.050',
	minHeight: '32px',
	color: 'color.text',
	transitionProperty: 'color, background-color',
	transitionDuration: '200ms',
	display: 'flex',
	gap: 'space.100',
	alignItems: 'center',
	fontWeight: token('font.weight.medium'),

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},

	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const brandStyles = xcss({
	color: 'color.text.brand',
	borderColor: 'color.border.brand',
});

const warningStyles = xcss({
	borderColor: 'color.border.warning',
});

const dangerStyles = xcss({
	borderColor: 'color.border.danger',
});

const iconStyles = xcss({
	display: 'flex',
	// icon size cannot grow and shrink
	flexGrow: 0,
	flexShrink: 0,
	alignSelf: 'center',
	fontSize: 0,
	lineHeight: 0,
	userSelect: 'none',
});

const sparkleAnimation = keyframes({
	'0%': { transform: 'scale(0)', opacity: 0 },
	'40%': { transform: 'scale(1)', opacity: 1 },
	'80%': { transform: 'scale(0)', opacity: 0 },
	'100%': { transform: 'scale(0)', opacity: 0 },
});

const sparkleStyles = css({
	flexShrink: '0',
	'&:hover': {
		// This is the only way to add the keyframes which target the inner parts of the icon
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles
		'#starTopRight': {
			animationName: sparkleAnimation,
			animationDuration: '2s',
			animationTimingFunction: 'ease-in-out',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
			transformBox: 'fill-box',
		},
		// This is the only way to add the keyframes which target the inner parts of the icon
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles
		'#starBottomRight': {
			animationName: sparkleAnimation,
			animationDuration: '2s',
			animationTimingFunction: 'ease-in-out',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
			transformBox: 'fill-box',
			// We delay this star's animation so the two stars sparkle separately
			animationDelay: '0.5s',
			// Because this star has an animation delay, we need to make it invisible to begin with
			// so it doesn't show up in its unanimated state for the first 0.5s of the animation (or however long we set the delay to).
			opacity: 0,
		},
	},
});

interface EditionAwarenessButtonProps extends Exclude<PressableProps, 'placeholder'> {
	status: 'default' | 'warning' | 'danger';
}

export const EditionAwarenessButton = forwardRef<HTMLButtonElement, EditionAwarenessButtonProps>(
	({ children, status, ...rest }, ref) => (
		<div css={sparkleStyles}>
			<Pressable
				{...rest}
				ref={ref}
				xcss={[
					buttonStyles,
					status === 'default' && brandStyles,
					status === 'warning' && warningStyles,
					status === 'danger' && dangerStyles,
				]}
			>
				<Box xcss={iconStyles}>
					{status === 'default' && <SparkleIcon label="" primaryColor="var(--ds-icon-discovery)" />}
					{status === 'warning' && <WarningIcon label="" color="var(--ds-icon-warning)" />}
					{status === 'danger' && <WarningIcon label="" color="var(--ds-icon-danger)" />}
				</Box>
				{children}
			</Pressable>
		</div>
	),
);
