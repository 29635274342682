/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { PrimaryButton } from '@atlaskit/atlassian-navigation';
import ChevronIcon from '@atlaskit/icon/glyph/chevron-down';

import { RenotifySpotlightTriggerChevron } from './styled';

interface Props {
	testId: string;
	triggerProps: any;
	children: React.ReactNode;
}

function RenotifyMenuButton(props: Props) {
	const { testId, triggerProps, children } = props;

	return (
		<PrimaryButton
			data-testid={testId}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="renotify-menu-button"
			iconAfter={
				<RenotifySpotlightTriggerChevron>
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
					<ChevronIcon label="dropdown-chevron" />
				</RenotifySpotlightTriggerChevron>
			}
			{...triggerProps}
		>
			{children}
		</PrimaryButton>
	);
}

export default RenotifyMenuButton;
