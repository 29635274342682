import { FabricChannel } from '@atlaskit/analytics-listeners';

export const ANALYTICS_CHANNEL = FabricChannel.aiMate;

export type InteractionSource =
	| 'chatInput'
	| 'followUps'
	| 'movedOver'
	| 'emptyStateSuggestions'
	| 'viewAgentConversationStarters'
	| 'urlParam'
	| 'profileCardConversationStarters'
	| 'ntpSuggestion'
	| 'rightClickDefine'
	| 'rightClickSummarize';

// Which context a follow up was triggered from
export type FollowUpSource =
	| 'none'
	| 'sain'
	| 'reading-aids'
	| 'page-summary'
	| 'ai-mate'
	| 'extension';
